import React from "react";
import authorImg from "images/animal_friends_img@2x.png";
// import ImaniImg from "images/imani-large@2x.png";

export default function AuthorSection() {
  return (
    <section id="about-author" className="ltblue-section">
      <div className="container">
        <div className="section-column">
          <div className="row">
            <div className="col-12 col-md-4">
              <img
                className="img-fluid"
                src={authorImg}
                alt="about the authors"
              />
            </div>
            <div className="col-12 col-md-8">
              <p className="h2 display-6 fw-bold text-center">About the Authors</p>
              <p className="fs-5 lh-base my-4">
                <span className="fw-bold">Dr. Clarissa Henry</span><br/>
                Dr. Clarissa Henry-Adekanbi is a mother of three children, school psychologist, and educator. For more than two decades, she has worked with children of a variety of ages teaching them the social emotional skills they need to learn and grow. Through her books and daily interactions, Dr. Henry fills her children and students with positive messages to nurture their self-esteem and self-confidence and teaches them the tools they need to fix small problems. Clarissa lives in Connecticut with her family.
              </p>
              <p className="fs-5 lh-base my-4">
                <span className="fw-bold">David Henry</span><br/>
                Since David D. Henry II was a child, he loved discovering how things worked. He used his creativity to break things apart and create something new. David turned that passion into a small business allowing him to turn clients' visions for home remodels into reality. As the Director of Marketing at Henry and Jeffers Publishing, David brings his passion for this work with him each day. David is also a father of three who lives in New York City. He consistently speaks positivity into his children to help them learn and grow. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
