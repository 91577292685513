import React from "react";
import mainImg from "images/ilm_main_img@2x.png";

import { AMAZON_BOOK_LINKS } from "utils/constants";

export default function Home() {
  return (
    <section id="home" className="top-section">
      <div className="container">
        <div className="section-column">
          <div className="row row-cols-2">
          <div className="col-12 col-md-6">
              <img className="img-fluid" src={mainImg} alt="ilm main" />
            </div>
            <div className="col-12 col-md-6">
              <div className="col">
                <p className="h1 display-3 fw-normal text-center">
                  You are Loved!
                </p>
                <p className="fs-5 lh-base my-4">
                Our words and actions have power. I’m Loving Me takes us on a journey with five animal friends. Throughout the book, we see the positive messages that members of their village pour into their lives. The positive messages we say to our children matter and help to build their self-esteem and self-confidence. The book also highlights the importance of children loving their authentic self.
                </p>
              </div>
              {/* <div className="d-grid gap-2 col-6 mx-auto text-center">
                <a
                  href={AMAZON_BOOK_LINKS}
                  className="nav-link"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="btn btn-primary btn-lg">BUY NOW</button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
